.image-container {
  position: relative;
  display: inline-block;
  padding-top: -15px;
}

.point {
  position: absolute;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  /* background-color: red; */ /* Comentamos esto, ya que se establecerá dinámicamente */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  justify-content: center;
  align-items: center;
  text-align: center;
}

.blue {
  background-color: blue;
}

.orange {
  background-color: orange;
}

.red {
  background-color: red;
}

.icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  /* background-color: #ffffff; */
  border-radius: 50%;
}

.device-info {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  /* background-color: #ffffff; */
  padding: 10px;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}


.temperature-table {
  width: 30%;
  border-collapse: collapse;
}

.temperature-table th,
.temperature-table td {
  padding: 10px;
  text-align: center;
}

.temperature-table th {
  background-color: #f2f2f2;
  font-weight: bold;
}

.color-gradation {
  width: 100px;
  height: 20px;
  border-radius: 4px;
}

/* Estilos adicionales para hacer que la tabla sea más adaptable */
@media (max-width: 600px) {
  .temperature-table th,
  .temperature-table td {
    padding: 5px;
    font-size: 12px;
  }

  .color-gradation {
    width: 80px;
    height: 16px;
  }
}