.fancy-button {
  display: block;
  /* width: 200px;
    border: 1px solid #AAA; */
  text-decoration: none;
  color: #222;
  /* padding: 6px 10px; */
  margin-top: 10px;
  /* text-align: center; */
  position: relative;
  transition: color 0.3s;
  font-family: "Source Sans Pro", sans-serif;
}

.fancy-button::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  background-color: darkslategrey;
  z-index: -1;

  left: 100%;
  width: 0%;
  transition: 0.3s;
}
.fancy-button:hover {
  color: blue;
  cursor: pointer;
  /* text-decoration: underline; */
}
.fancy-button:hover::before {
  left: 0%;
  width: 100%;
  transition: width 0.3s;
}
