.MyDragHandleClassName {
  cursor: move;
  /* height: 40px; */
  display: inline;
  line-height: 38px;
  /* background: #61dafb; */
  max-height: 480px;
}
.react-grid-layout {
  border: 6px groove #fafafe;
  width: 100%;
  /* padding-right: 30px; */
  background-color: rgba(230, 230, 250, 0.212);
  /* background-color: white; */
  /* height: 200px !important; */
}
/* .react-grid-item {
  background-color: white;
  border-radius: 5px;
  padding: 10px;
} */

.rotar-icono {
  transform: rotate(180deg);
  transition: transform 0.5s ease;
}

.drag-handle{
  cursor: move;
  height: 10px; /* Altura de la zona de agarre en la parte superior */
  width: 100%; /* Ancho igual al ancho del elemento */
  position: absolute;
  top: 0;
  left: 0;
  
}
