:root {
  --primary-color: #114396;
  --secondary-color: #efefef;
  --primary-background-color: #cecfc9;
  --secondary-background-color: #ffffff;
  --auxiliary1-background-color: #efefef;

  --text-color-1: #4f4f4f;
  --text-color-2: white;
}

.buttonGeneral {
  background-color: var(--primary-color);
  color: var(--text-color-2);
}

* {
  margin: 0;
  padding: 0;
}

.App {
  font-family: sans-serif;
  text-align: center;
}

.form .form__custom-button {
  margin-top: 50px;
  color: var(--text-color-2);
  background-color: var(--primary-color);
}

.titulo-card {
  font-style: bold;
  font-size: 16px !important;
  color: var(--primary-color) !important;
}

.subtitulo-card {
  font-style: bold;
  font-size: 14px !important;
  color: var(--text-color-1) !important;
}

.titulo-pagina {
  font-size: 34px !important;
  text-align: center;
  color: var(--primary-color) !important;
}

.body-card-ubicaciones-fincas {
  height: 65vh;
}

.imagenes {
  width: 100%;
}
