.input-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}

.input-container label {
  margin-bottom: 0.5rem;
  font-size: 0.9rem;
}

.input-container input {
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 3px;
  font-size: 1rem;
}

.info-icon {
  position: relative;
  display: inline-block;
  margin-left: 0.5rem;
}

.info-icon:hover .info-text {
  display: block;
}

.info-text {
  position: absolute;
  top: 100%;
  left: -1rem;
  z-index: 1;
  display: none;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 3px;
  background-color: #fff;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.2);
  font-size: 0.8rem;
  width: 20rem;
}
