.modal-container {
  display: table;
  width: 100%;
  height: 100%;
}

.modal-backdrop {
  display: table-cell;
  vertical-align: middle;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal {
  position: relative;
  display: flex;
  /* flex-direction: column; */
  justify-content: space-between;
  /* align-items: center; */
  width: 50%;
  height: 100%;
  margin-left: auto;
  background-color: white;
  padding: 1rem;
  animation-name: slide-inn;
  animation-duration: 0.5s;
}

@media (max-width: 768px){
  .modal {
    width: 80%;
  }
}

@keyframes slide-inn {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}
