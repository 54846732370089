.row-style-impar {
  background-color: rgb(224, 224, 224);
  padding: 5px;
  border: solid white 3px;
}

.row-style-par {
  background-color: rgba(224, 224, 224, 0.4);
  padding: 5px;
  border: solid white 3px;
}

.table-container {
  margin: 25px 0 25px 0;
  width: 45%;
}