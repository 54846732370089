.widget {
  /* background-color: #0069d9; */
  width: 100%;
  height: 100%;
  /* background-color: #f9f9f9; */
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.04);
  padding: 12px;
  font-family: Arial, sans-serif;
  font-size: 16px;
  line-height: 1.5;
}

.widget h3 {
  font-size: 20px;
  font-weight: bold;
  margin-top: 0;
}

.widget p {
  margin-bottom: 0;
}

.widget button {
  background-color: #007bff;
  border: none;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
  font-size: 16px;
  padding: 8px 16px;
}

.widget button:hover {
  background-color: #0069d9;
}
